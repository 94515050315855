<template>
  <section class="content-section funding">
    <div>
      <h1>Support the development</h1>
      <p>
        Furry World is still under development and, although the game is
        currently open to anyone that wishes to play it, we want to improve it
        by adding new customization options, activities to participate and
        levels to explore. If you wish to support our development and see the
        game evolve, please consider donating on itch.io and sharing the project
        with your friends!
      </p>

      <div class="call-to-action-container">
        <a href="https://furryworld.itch.io/game" class="call-to-action">
          Download on itch.io
        </a>
      </div>
    </div>
    <vue-picture-swipe
      class="picture-swipe"
      :items="[
        // {
        //   src: 'screenshots/screenshot_00.png',
        //   thumbnail: 'screenshots/screenshot_00_thumb.png',
        //   w: 1908,
        //   h: 978,
        //   title: 'Game Screenshot',
        // },
        // {
        //   src: 'screenshots/screenshot_01.png',
        //   thumbnail: 'screenshots/screenshot_01_thumb.png',
        //   w: 1908,
        //   h: 978,
        //   title: 'Game Screenshot',
        // },
        {
          src: 'screenshots/screenshot_02.png',
          thumbnail: 'screenshots/screenshot_02_thumb.png',
          w: 1913,
          h: 976,
          title: 'Game Screenshot',
        },
        {
          src: 'screenshots/screenshot_03.png',
          thumbnail: 'screenshots/screenshot_03_thumb.png',
          w: 1914,
          h: 976,
          title: 'Game Screenshot',
        },
        {
          src: 'screenshots/screenshot_04.png',
          thumbnail: 'screenshots/screenshot_04_thumb.png',
          w: 1910,
          h: 977,
          title: 'Game Screenshot',
        },
        {
          src: 'screenshots/screenshot_05.png',
          thumbnail: 'screenshots/screenshot_05_thumb.png',
          w: 1911,
          h: 978,
          title: 'Game Screenshot',
        },
        {
          src: 'screenshots/screenshot_06.png',
          thumbnail: 'screenshots/screenshot_06_thumb.png',
          w: 1911,
          h: 982,
          title: 'Game Screenshot',
        },
        {
          src: 'screenshots/screenshot_07.png',
          thumbnail: 'screenshots/screenshot_07_thumb.png',
          w: 1909,
          h: 981,
          title: 'Game Screenshot',
        },
      ]"
    ></vue-picture-swipe>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import VuePictureSwipe from "vue-picture-swipe";

export default defineComponent({
  name: "Funding",
  components: {
    VuePictureSwipe,
  },
});
</script>

<style scoped lang="scss">
.funding {
  background-color: #dadbdb;
  background-image: url("../tinified/bg_03.png");
  align-items: flex-start;

  .call-to-action-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
