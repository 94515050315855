
import { defineComponent } from "vue";
import VuePictureSwipe from "vue-picture-swipe";

export default defineComponent({
  name: "Funding",
  components: {
    VuePictureSwipe,
  },
});
